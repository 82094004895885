import {BasicObject, SafeAny} from '@/types';
import type {MessageDataPayload} from '../webview/webview';
import {toAllCasinoGame, toGame} from '../game-navigate';
import {goTo, debounce, toSports, envConfig} from '@utils';
import {order} from '@/utils/magic-box';
import {postReport, TReportType, TSourceType} from '@/services/global.service';
import {getGameURL} from '@/pages/home/home.service';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {View} from 'react-native';
import Spin from '@/components/basic/spin';
import theme from '@/style';
import {useWebView} from '../hooks/webview.hooks';
import globalStore from '@/services/global.state';
import DeviceInfo from 'react-native-device-info';
import {getAdjustAttribution} from '@/utils/adjust';
import {packageId} from '@/config';
import {useFocusEffect} from '@react-navigation/native';

const gameMap: {
  [key: string]: string;
} = {
  color: 'Color',
  dice: 'Dice',
  '3Digit': 'Digit',
  quick3d: 'Digit',
  scratch: 'Scratch',
  matka: 'GameSatta',
  kerala: 'GameWebView',
  quickStateLottery: 'MixLottery',
  stateLottery: 'MixLottery',
};

enum EventType {
  PLAY = 'play',
  FOLLOWED = 'followed',
  LOGIN = 'login',
  SUCCESS = 'success',
  FAIL = 'fail',
  BACK = 'back',
  USER_EDITOR = 'userEditor',
  START_GAME = 'startGame',
}

interface IDataFromChat {
  source: string | 'chat-room';
  payload: MessageDataPayload;
}

const IMWebView = () => {
  const [pageLoading, setPageLoading] = React.useState(true);
  const tokenRef = useRef('');
  const [token, setToken] = useState('');
  const [version, setVersion] = useState(0);
  useEffect(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      setToken(token || '');
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  const getSportURL = debounce((gametype: string) => {
    getGameURL(gametype)
      .then((url: string) => {
        toSports(gametype.split('_')[0].toUpperCase() + ' Sports', url);
      })
      .catch(e => console.error('error', e));
  });

  const playNow = (fromData: SafeAny) => {
    const {gameType, rawData, gameName} = fromData;
    const gameCode = gameMap[gameType];
    localStorage.setItem('PlayNow', `im-${gameCode}`);
    const jumpParams = {} as SafeAny;

    switch (gameType) {
      case 'quick3d':
      case '3Digit': {
        jumpParams.id = rawData?.pickThreeId;
        jumpParams.pickName = gameName;
        jumpParams.pickGameType = gameType === 'quick3d' ? 'quick' : 'normal';
        // jumpParams.cycle = item.cycle;
        break;
      }
      case 'matka': {
        jumpParams.type = 'kerala';
        jumpParams.lotteryName = rawData?.lotteryName;
        break;
      }
      case 'kerala': {
        const rawData2Str = rawData || {};
        jumpParams.id = rawData?.matkaId;
        jumpParams.params = `data=${JSON.stringify(rawData2Str)}`;
        break;
      }
      case 'quickStateLottery':
      case 'stateLottery': {
        jumpParams.lotteryID = rawData?.lotteryID;
        jumpParams.isQuick = gameType === 'quickStateLottery' ? '1' : '0';
        break;
      }
    }
    if (gameType === 'scratch' && window) {
      const curLink = window.location.href.split('?')[0];
      const jumpLink = curLink.replace('webview', 'index/scratch');
      window.location.replace(jumpLink);
    } else if (['quickRace', 'casino', 'live'].includes(gameType)) {
      toGame({
        source: rawData?.source,
        name: rawData?.gameName,
        gameUrl: rawData?.gameUrl,
        id: rawData?.gameId,
        tripartiteUniqueness: rawData?.tripartiteUniqueness,
      });
    } else if (gameType === 'sport') {
      getSportURL(
        (rawData?.source || '').toLowerCase().split(' ')[0] + '_sport',
      );
    } else {
      goTo(gameCode, jumpParams);
    }
  };

  const handleMessage = (
    data: string | BasicObject | IDataFromChat,
    postMessage?: Function,
  ) => {
    if (data === 'pageLoading:show') {
      setPageLoading(true);
      return;
    }
    if (data === 'pageLoading:hide') {
      setPageLoading(false);
      return;
    }
    if (typeof data === 'object' && data.source === 'chat-room') {
      localStorage.setItem('from', 'im');
      const {event, data: fromData} = data.payload || {};
      const {gameType, userInviteCode} = fromData || {};
      localStorage.setItem('invitationCode', userInviteCode);
      if (fromData.shareId) {
        localStorage.setItem('shareUserId', fromData.shareId);
      }
      switch (event) {
        case EventType.BACK: {
          const curLink = window.location.href.split('?')[0];
          const jumpLink = curLink.replace('index/chat', 'index/home');
          window.location.replace(jumpLink);
          break;
        }
        case EventType.LOGIN:
          // const curLink = window.location.href.split('?')[0];
          // const jumpLink = curLink.replace('index/chat', 'login?fromPage=chat');
          // window.location.replace(jumpLink);
          goTo('Login', {fromPage: 'chat'});
          break;
        case EventType.PLAY: {
          playNow(fromData);
          break;
        }
        case EventType.FOLLOWED: {
          order(fromData.rawData, gameType)
            .then(() => {
              const user = localStorage.getItem('user') || '{}';
              const userId = JSON.parse(user).userId;
              postReport({
                reportType: TReportType.BETS,
                gameName: fromData?.gameName,
                gameType,
                userId,
                inviteCode: fromData?.userInviteCode,
                gameId: fromData?.gameId,
                issueNo: fromData?.issNo,
                sourceType: TSourceType.IM,
                shareUserId: fromData?.rawData?.userId || fromData?.shareId,
              });
              postMessage &&
                postMessage({
                  source: 'chat-room',
                  payload: {
                    event: EventType.SUCCESS,
                    data: {
                      message: 'success!',
                    },
                  },
                });
            })
            .catch(() => {
              postMessage &&
                postMessage({
                  source: 'chat-room',
                  payload: {
                    event: EventType.FAIL,
                    data: {
                      message: 'failed!',
                    },
                  },
                });
            });
          break;
        }
        case EventType.USER_EDITOR: {
          const curLink = window.location.href.split('?')[0];
          const jumpLink = curLink.replace('index/chat', 'profile');
          window.location.replace(jumpLink);
          break;
        }
        case EventType.START_GAME: {
          toAllCasinoGame(fromData?.gameId);
        }
      }
    }
  };

  const [url, setUrl] = useState('');

  useEffect(() => {
    (async () => {
      const deviceCode = globalStore.isWeb
        ? getAdjustAttribution()?.adid
        : await DeviceInfo.getAndroidId();
      const _url = `${
        envConfig.IMURL
      }/#/home?packageId=${packageId}&originToken=${token}&isVisitor=${!token}&deviceCode=${deviceCode}&full=2`;
      setUrl(_url);
    })();
  }, [token]);

  const {render} = useWebView({
    originUrl: url,
    onMessage: handleMessage,
    onLoadEnd: () => {
      setPageLoading(false);
    },
  });

  useFocusEffect(
    useCallback(() => {
      if (token !== tokenRef.current) {
        setVersion(v => v + 1);
        tokenRef.current = token;
      }
    }, [token]),
  );

  return (
    <View key={version} style={[theme.fill.fill, theme.flex.col]}>
      <Spin loading={pageLoading} style={[theme.flex.flex1]}>
        {render}
      </Spin>
    </View>
  );
};

export default IMWebView;
