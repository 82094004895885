import i18n from '@/i18n';
import {envConfig} from '@/utils';
import globalStore from '@/services/global.state';
import {errorLog, goTo, navigateTo} from '@/utils';
import {
  getSlotegratorGameStart,
  getKMAuthorize,
  postLiveAuthorize,
  postUserInfo,
  getWSStart,
  getEvolution,
  getCD,
  getAllCasinoRedirect,
} from './service';
import {BasicObject} from '@/types';
import {gameNavigateVersion, packageId} from '@/config';
import DeviceInfo from 'react-native-device-info';
import {getAdjustAttribution} from '@/utils/adjust';

export function navigateGame(gameName: string, link: string) {
  const homeUrlSpell = globalStore.isWeb
    ? `homeurl=${window.location.origin}/index/casino`
    : '';

  const url = `${link}${
    link.indexOf('?') === link.length - 1
      ? ''
      : link.endsWith('cert=')
      ? ''
      : '&cert='
  }${globalStore.token}&${homeUrlSpell}`;

  goTo('WebView', {
    header: true,
    hideAmount: true,
    headerTitle: gameName,
    originUrl: url,
  });
}

export async function toLiveCasino(
  baseUrl: string,
  table: string,
  name?: string,
) {
  let url = baseUrl;
  if (url.lastIndexOf('?') === -1) {
    url = `${url}?`;
  }
  if (url.lastIndexOf('?') !== url.length - 1) {
    url = `${url}&`;
  }
  try {
    globalStore.globalLoading.next(true);
    const [userInfo, authorize] = await Promise.allSettled([
      postUserInfo(),
      postLiveAuthorize(),
    ]);
    if (userInfo.status === 'fulfilled' && authorize.status === 'fulfilled') {
      const uinfo = userInfo.value;
      const auth = authorize.value;
      url = `${url}token=${auth.authorize}&username=${
        uinfo.userPhone
      }&mobile=true&lobby=A8870&lang=${
        globalStore.lang
      }&options=defaulttable=${table}${
        globalStore.channel !== 'h5' ? ',webview=1' : ''
      }`;
      goTo('WebView', {
        header: true,
        hideAmount: true,
        headerTitle: name || i18n.t('home.live-casino.title'),
        originUrl: url,
      });
    } else {
      errorLog('error', userInfo, authorize);
    }
  } finally {
    globalStore.globalLoading.next(false);
  }
}

export async function toSlotegrator(name: string, id: string) {
  globalStore.globalLoading.next(true);
  try {
    const url = await getSlotegratorGameStart(id);
    goTo('WebView', {
      header: true,
      hideAmount: true,
      headerTitle: name,
      originUrl: url,
    });
  } finally {
    globalStore.globalLoading.next(false);
  }
}

export async function toKMGame(name: string, gameUrl: string, gcode: string) {
  globalStore.globalLoading.next(true);
  try {
    const tokenResult = await getKMAuthorize(gcode);
    if (!tokenResult.active) {
      globalStore.globalWaringTotal(i18n.t('tip.game-not-active'));
      return;
    }
    goTo('WebView', {
      header: true,
      hideAmount: true,
      headerTitle: name,
      originUrl: `${gameUrl}&gcode=${gcode}&token=${tokenResult.authToken}`,
    });
  } finally {
    globalStore.globalLoading.next(false);
  }
}

export async function toWSGame(name: string, id: string) {
  globalStore.globalLoading.next(true);
  try {
    const url = await getWSStart(id);
    goTo('WebView', {
      header: true,
      hideAmount: true,
      headerTitle: name,
      originUrl: url,
    });
  } finally {
    globalStore.globalLoading.next(false);
  }
}

export async function toEvolutionGame(name: string, id: string) {
  globalStore.globalLoading.next(true);
  try {
    const url = await getEvolution(id);
    goTo('WebView', {
      header: true,
      hideAmount: true,
      headerTitle: name,
      originUrl: url,
    });
  } finally {
    globalStore.globalLoading.next(false);
  }
}
export async function toCDGame(name: string, id: string) {
  globalStore.globalLoading.next(true);
  try {
    const url = await getCD(id);
    goTo('WebView', {
      header: true,
      hideAmount: true,
      headerTitle: name,
      originUrl: url,
    });
  } finally {
    globalStore.globalLoading.next(false);
  }
}

export type LotteryType =
  | 'scratch'
  | '3Digit'
  | 'pick3'
  | 'kerala'
  | 'color'
  | 'matka'
  | 'dice';

export interface GameParams {
  // 游戏名称
  name?: string;
  // 用于判断跳转的厂商
  source: string;
  // 游戏url，部分厂商需要拼接url进行跳转
  gameUrl?: string;
  // 唯一标识，比如SA的defaulttable和KM的gCode
  tripartiteUniqueness?: string;
  // 游戏id，比如Slotegrator和WS-168需要用id获取跳转地址
  id?: number;
  // 游戏类型，如果不传类型默认是小游戏，传入类型且是lottery系的都是本家游戏，有对应跳转配置，对应的gameUrl就是拼接的参数
  type?: string;
}

function isLottery(type?: string): type is LotteryType {
  if (!type) {
    return false;
  }
  const lotteryTypes = [
    'scratch',
    '3Digit',
    'pick3',
    'kerala',
    'color',
    'matka',
    'dice',
  ];
  return lotteryTypes.indexOf(type) !== -1;
}

export async function toChat() {
  const token = globalStore.token;
  const deviceCode = globalStore.isWeb
    ? getAdjustAttribution()?.adid
    : await DeviceInfo.getAndroidId();
  const url = `${
    envConfig.IMURL
  }/#/home?packageId=${packageId}&originToken=${token}&isVisitor=${!token}&deviceCode=${deviceCode}`;
  goTo('WebView', {
    originUrl: url,
  });
}

export async function toAllCasinoGame(id?: number) {
  if (!id) {
    return;
  }

  globalStore.globalLoading.next(true);
  try {
    const info = await getAllCasinoRedirect(id);
    if (info) {
      const {gameName, gameUrl} = info;
      goTo('WebView', {
        header: true,
        hideAmount: true,
        headerTitle: gameName,
        originUrl: gameUrl,
      });
    }
  } finally {
    globalStore.globalLoading.next(false);
  }
}

export async function toGame({
  source,
  name,
  gameUrl,
  id,
  tripartiteUniqueness,
  type,
}: GameParams) {
  const _type = type?.toLowerCase();
  if (isLottery(_type)) {
    // lottery类型的游戏单独处理链接
    if (_type === 'scratch') {
      goTo('Scratch', {hideInnerTitle: '1', path: gameUrl});
      return;
    }
    const gameWebviewTypeHandler: Record<
      Exclude<LotteryType, 'scratch'>,
      string
    > = {
      '3Digit': 'digit',
      pick3: 'digit',
      kerala: 'kerala',
      color: 'color',
      dice: 'dice',
      matka: 'matka',
    };
    goTo('GameWebView', {
      type: gameWebviewTypeHandler[_type],
      params: gameUrl,
    });
    return;
  }
  if (!globalStore.token) {
    goTo('Login');
    return;
  }

  // TODO 如果singam、bh、99lotto全上，则gameHandler相关引用代码可以去除
  if (gameNavigateVersion >= 1) {
    toAllCasinoGame(id);
    return;
  }

  const gameHandler: Record<string, () => void> = {
    'SKY GAME': () => navigateGame(name || 'Casino', gameUrl || ''),
    SA: () =>
      toLiveCasino(gameUrl || '', tripartiteUniqueness || '', name || 'Live'),
    Slotegrator: () => toSlotegrator(name || 'Slotegrator', id + ''),
    KM: () => toKMGame(name || 'KM', gameUrl || '', tripartiteUniqueness || ''),
    WS168: () => toWSGame(name || 'WS', id + ''),
    Evolution: () => toEvolutionGame(name || 'Evolution', id + ''),
    CD: () => toCDGame(name || 'CD', id + ''),
  };
  gameHandler[source]?.();
}

/**
 * 通用地址跳转
 * @param url
 *  直接使用http/https的地址直接外部跳转；
    使用route:开头的情况，后面需要直接使用json字符串进行配置。比如route:{"routeName": "Home","routeParams": {"key": "value"}}这种
    route参数：
    type: "uniapp" | "reacth5" | "rn" 对应平台，默认"rn"；
    needLogin 是否进行登录，如果是一个非空字符串就需要判断登录
    path uniapp或者reacth5平台特有参数，指定的可带参路径
    routeName rn平台特有参数，指定路由名称
    routeParams rn平台特有参数，指定路由所需参数
    noTitle 默认false，如果是true代表不需要头部
    serviceRight 右侧使用客服
    游戏页相关跳转
    route:{"routeName": "WebView", "routeParams": {"hideAmount": true, "originUrl": "游戏url"}}
    使用游戏开头的情况
    SA:{"url": "url", "table": "100"},Slotegrator:{"id": 10000},SKY:{"url": "url"}


 * @param title 外部页面如果有标题就带上顶部标题，没有就算了
 * @returns
 */
export async function goToUrl(url: string, title?: string) {
  if (url.startsWith('http')) {
    // goTo('WebView', {
    //   header: !!title,
    //   hideAmount: true,
    //   headerTitle: title || '',
    //   originUrl: url,
    // });
    navigateTo(url);
    return;
  }
  if (url.startsWith('route:')) {
    try {
      const jsonResult = JSON.parse(url.substring('route:'.length));
      jsonResult.type = jsonResult.type || 'rn';
      jsonResult.needLogin = jsonResult.needLogin || false;
      jsonResult.noTitle = jsonResult.noTitle || false;
      if (jsonResult.needLogin && !globalStore.token) {
        goTo('Login');
        return;
      }
      if (jsonResult.type === 'uniapp' || jsonResult.type === 'reacth5') {
        goTo('WebView', {
          header: !!title && !jsonResult.noTitle,
          hideAmount: false,
          headerTitle: jsonResult.noTitle ? '' : title || '',
          isReactH5: jsonResult.type === 'uniapp' ? '0' : '1',
          serverRight: jsonResult.serverRight || '',
          path: jsonResult.path,
        });
        return;
      }
      if (jsonResult.type === 'rn') {
        goTo(
          jsonResult.routeName,
          Object.assign(
            {},
            jsonResult.routeParams || {},
            !!title && !jsonResult.noTitle
              ? {
                  header: true,
                  headerTitle: title || '',
                }
              : {},
          ),
        );
        return;
      }
    } catch (e) {
      errorLog('jsonresult', e);
      globalStore.globalWaringTotal(i18n.t('invalid-url'));
    }
    return;
  }

  // TODO 如果singam、bh、99lotto全上了新版跳转，则gamesOptions后面的相关代码可以去除
  if (gameNavigateVersion >= 1 && url.startsWith('casino:')) {
    const gameId = url.substring('casino:'.length);
    if (gameId !== '') {
      toAllCasinoGame(+gameId);
    }
    return;
  }

  const gamesOptions = [
    {
      prefix: 'SA:',
      toGame: (jsonResult: BasicObject) =>
        toLiveCasino(jsonResult.url, jsonResult.table, title),
    },
    {
      prefix: 'Slotegrator:',
      toGame: (jsonResult: BasicObject) =>
        toSlotegrator(title || jsonResult.title || 'Casino', jsonResult.id),
    },
    {
      prefix: 'SKY:',
      toGame: (jsonResult: BasicObject) =>
        navigateGame(title || jsonResult.title || 'Casino', jsonResult.url),
    },
    {
      prefix: 'WS168:',
      toGame: (jsonResult: BasicObject) =>
        toWSGame(title || jsonResult.title || 'Live', jsonResult.id),
    },
    {
      prefix: 'KM:',
      toGame: (jsonResult: BasicObject) =>
        toKMGame(
          title || jsonResult.title || 'Casino',
          jsonResult.url,
          jsonResult.gcode,
        ),
    },
    // 'Evolution:{"title": "game name", "id": "game id"}'
    {
      prefix: 'Evolution:',
      toGame: (jsonResult: BasicObject) =>
        toEvolutionGame(title || jsonResult.title || 'Casino', jsonResult.id),
    },
    // 'CD:{"title": "game name", "id": "game id"}'
    {
      prefix: 'CD:',
      toGame: (jsonResult: BasicObject) =>
        toCDGame(title || jsonResult.title || 'Casino', jsonResult.id),
    },
  ];
  const gameOption = gamesOptions.find(game => url.startsWith(game.prefix));
  if (!gameOption) {
    return;
  }
  try {
    const jsonResult = JSON.parse(url.substring(gameOption.prefix.length));
    gameOption.toGame(jsonResult);
  } catch (e) {
    errorLog('jsonresult', e);
    globalStore.globalWaringTotal(i18n.t('invalid-url'));
  }
}
