import {useModal} from '@basicComponents/modal';
import Text from '@basicComponents/text';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import theme from '@style';
import {VersionInfo, checkVersion} from '@/app.service';
import React, {useEffect} from 'react';
import {BackHandler, Image, Linking, ScrollView, View} from 'react-native';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {useScreenSize} from './size.hooks';
import {inApp, openURLInAPP} from '@/utils/interface';

export function useVersionModal(
  autoCheckVersion?: boolean,
  afterCheck?: (res?: VersionInfo) => void,
  showTip = true,
) {
  const {i18n} = useTranslation();
  const {calcActualSize} = useScreenSize();
  const [versionInfo, setVersionInfo] = React.useState<VersionInfo | null>(
    null,
  );
  /** 是否需要更新 */
  const versionModal = useModal(
    <View
      style={[
        theme.flex.col,
        theme.flex.centerByCol,
        theme.position.rel,
        {
          width: calcActualSize(280),
        },
      ]}>
      {versionInfo && (
        <>
          <Image
            source={require('@components/assets/imgs/update-pop.webp')}
            style={[
              {
                width: calcActualSize(280),
                height: calcActualSize(184),
                marginTop: -calcActualSize(17),
              },
            ]}
          />
          <View
            style={[
              {
                padding: calcActualSize(20),
                paddingTop: theme.paddingSize.zorro,
                marginTop: -calcActualSize(40),
              },
            ]}>
            <Text size="large" blod>
              Update To
            </Text>
            <Text size="large" blod>
              The New Version
            </Text>
            <Text
              size="medium"
              style={{
                marginTop: calcActualSize(12),
              }}>
              {versionInfo?.levelContent}
            </Text>
            <View
              style={[
                theme.margin.topl,
                theme.flex.row,
                theme.fill.fillW,
                theme.flex.centerByCol,
                {
                  marginTop: calcActualSize(20),
                  gap: calcActualSize(8),
                  height: calcActualSize(40),
                },
              ]}>
              {versionInfo?.levelUp !== 3 && (
                <NativeTouchableOpacity
                  style={[
                    theme.flex.flex1,
                    theme.border.secAccent,
                    theme.fill.fillH,
                    theme.flex.center,
                    {
                      borderRadius: calcActualSize(8),
                    },
                  ]}
                  onPress={() => {
                    if (versionInfo?.levelUp === 3) {
                      BackHandler.exitApp();
                    } else {
                      versionModal.hide();
                    }
                  }}>
                  <Text size="medium" second textAlign="center">
                    {i18n.t('label.cancel')}
                  </Text>
                </NativeTouchableOpacity>
              )}
              <NativeTouchableOpacity
                style={[
                  theme.flex.flex1,
                  theme.background.primary,
                  theme.fill.fillH,
                  theme.flex.center,
                  {
                    borderRadius: calcActualSize(8),
                  },
                ]}
                onPress={() => {
                  Linking.openURL(versionInfo!.downUrl);
                }}>
                <Text size="medium" blod color={theme.basicColor.white}>
                  {i18n.t('label.download')}
                </Text>
              </NativeTouchableOpacity>
            </View>
          </View>
        </>
      )}
    </View>,
    {
      backDropClose: false,
      overlayStyle: {
        padding: 0,
        borderTopLeftRadius: calcActualSize(28),
        borderTopRightRadius: calcActualSize(28),
        borderBottomLeftRadius: calcActualSize(24),
        borderBottomRightRadius: calcActualSize(24),
        ...theme.margin.lrxxl,
      },
    },
  );

  useEffect(() => {
    if (globalStore.isAndroid && autoCheckVersion) {
      checkVersion()
        .then(res => {
          if (res) {
            setVersionInfo(res);
            versionModal.show();
          }
          afterCheck?.(res);
        })
        .catch(() => {
          afterCheck?.();
        });
    } else {
      afterCheck?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoCheckVersion]);

  const handleUpdate = () => {
    showTip && globalStore.globalLoading.next(true);
    checkVersion()
      .then(res => {
        if (res) {
          setVersionInfo(res);
          versionModal.show();
        } else if (showTip) {
          globalStore.globalTotal.next({
            type: 'success',
            message: i18n.t('no-update'),
          });
        }
      })
      .finally(() => {
        showTip && globalStore.globalLoading.next(false);
      });
  };

  return {
    versionModal,
    handleUpdate,
  };
}

type VersionUpdateModalProp = {
  info: VersionInfo;
  onClose: () => void;
};

export function VersionUpdateModal(props: VersionUpdateModalProp) {
  const {i18n} = useTranslation();
  const {calcActualSize} = useScreenSize();

  const versionInfo = props.info;
  return (
    <View
      style={[
        theme.flex.col,
        theme.flex.centerByCol,
        theme.position.rel,
        {
          width: calcActualSize(280),
        },
      ]}>
      <>
        <Image
          source={require('@components/assets/imgs/update-pop.webp')}
          style={[
            {
              width: calcActualSize(280),
              height: calcActualSize(184),
              marginTop: -calcActualSize(17),
            },
          ]}
        />
        <View
          style={[
            theme.background.white,
            {
              padding: calcActualSize(20),
              paddingTop: theme.paddingSize.zorro,
              marginTop: -calcActualSize(40),
              width: '100%',
            },
          ]}>
          <Text size="large" blod>
            Update To
          </Text>
          <Text size="large" blod>
            The New Version
          </Text>
          <ScrollView
            style={{
              maxHeight: 180,
            }}>
            <Text
              size="medium"
              style={{
                marginTop: calcActualSize(12),
              }}>
              {versionInfo.levelContent}
            </Text>
          </ScrollView>
          <View
            style={[
              theme.margin.topl,
              theme.flex.row,
              theme.fill.fillW,
              theme.flex.centerByCol,
              {
                marginTop: calcActualSize(20),
                gap: calcActualSize(8),
                height: calcActualSize(40),
              },
            ]}>
            {versionInfo.levelUp !== 3 && (
              <NativeTouchableOpacity
                style={[
                  theme.flex.flex1,
                  theme.border.secAccent,
                  theme.fill.fillH,
                  theme.flex.center,
                  {
                    borderRadius: calcActualSize(8),
                  },
                ]}
                onPress={() => {
                  props.onClose && props.onClose();
                  if (versionInfo.levelUp === 3) {
                    // BackHandler.exitApp();
                  } else {
                    // versionModal.hide();
                  }
                }}>
                <Text size="medium" second textAlign="center">
                  {i18n.t('label.cancel')}
                </Text>
              </NativeTouchableOpacity>
            )}
            <NativeTouchableOpacity
              style={[
                theme.flex.flex1,
                theme.background.primary,
                theme.fill.fillH,
                theme.flex.center,
                {
                  borderRadius: calcActualSize(8),
                },
              ]}
              onPress={() => {
                if (inApp) {
                  openURLInAPP(versionInfo.downUrl);
                } else {
                  Linking.openURL(versionInfo.downUrl);
                }
                if (versionInfo.levelUp !== 3) {
                  props.onClose && props.onClose();
                }
              }}>
              <Text size="medium" blod color={theme.basicColor.white}>
                {i18n.t('label.download')}
              </Text>
            </NativeTouchableOpacity>
          </View>
        </View>
      </>
    </View>
  );
}

export {checkVersion};
